@keyframes button-flash {
  0% {
    background-color: unset;
  }
  50% {
    background-color: #1f2229;
  }
  100% {
    background-color: unset;
  }
}

.menu {
  transition: width 0.3s ease-out;
  background-color: #2c303b;
  width: 70px;
  height: 100vh;
  color: #797b81;
  font-family: Quicksand, Georgia, 'Times New Roman', Times, serif;
  position: fixed;
  z-index: 30;

  &.open {
    width: 300px;
    overflow-y: auto;
  }

  &.open .label {
    opacity: 1 !important;
  }

  &.open .button:hover {
    padding-left: 40px;
  }

  &.open .header .toggle {
    opacity: 1;
  }

  &.open .header .image img:nth-child(2) {
    opacity: 1;
  }

  .header {
    height: 70px;
    display: flex;

    .image {
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      width: 150px;
      top: 20px;
      left: 15px;
      overflow: hidden;
      cursor: pointer;

      img {
        height: 35px;
      }

      img:nth-child(2) {
        opacity: 0;
        transition: opacity 0.1s ease-out;
      }
    }

    .toggle {
      opacity: 0;
      width: 70px;
      background-color: #2c303b;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.3rem;
      color: white;
      cursor: pointer;
      position: absolute;
      right: 0px;
      top: 23px;
      transition: opacity 0.1s ease-out;

      .lock-status {
        display: none;
      }

      &:hover {
        .toggle-icon {
          display: none;
        }

        .lock-status {
          display: block;
        }
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &.active {
        background-color: #1f2229;
      }

      &.flash {
        animation: button-flash 0.5s 4;
      }

      .button {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        transition: padding 0.2s ease-out;
        cursor: pointer;
        padding-left: 25px;
        position: relative;
        overflow: hidden;

        .icon {
          color: white;
          font-size: 1.3rem;
        }

        .label {
          opacity: 0;
          transition: opacity 0.1s ease-out 0.05s, left 0.2s ease-out;
          left: 60px;
          position: absolute;
        }

        .circle {
          background: white;
          border: red;
          background-color: green;
          height: 20px;
          -webkit-border-radius: 75px;
          width: 20px;
          text-align: center;
          color: white;
          left: 255px;
          position: absolute;
          font-size: 16px;
        }

        &:hover .label {
          left: 70px;
        }
      }
    }
  }
}
